import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Versions } from './Components/Versions';





function App() {
 
  return (
    <div className="App">
     
    <Versions/>
    </div>
  );
}

export default App;
