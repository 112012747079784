import React from 'react'
import { useContext } from 'react'
import { Authcontext } from '../../Context/Authcontext'
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';

export const Iframe = () => {
  const [DATA, setDATA] = useState<any>(
    JSON.parse(localStorage.getItem("DATA") || "") || {}
  );
  useEffect(()=>{
    setDATA( JSON.parse(localStorage.getItem("DATA") || "")  || {} );
   setelement(DATA);
     
    },[])
  
  let {element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}= useContext(Authcontext)
  const navigate=useNavigate(); 
  return (
   <div style={{width:"100vw", maxWidth:"450px"}}>
      <div className='home_bar1' >
        <div>
          <HiOutlineArrowLongLeft onClick={()=>navigate(-1)} className='left_arrow1' />
          <span>Back to car details</span>
        </div>
        <div className='cta_button_container'>
        <button>Book Now</button>
        </div>
      </div>
    <div className='iframe_main' >
       
      <iframe 
      src={element.ImageUrl2}>

    </iframe>
    </div>
   </div>

  )
}
