import Carousel from 'react-bootstrap/Carousel';
import { useContext } from 'react';
import { Authcontext } from './Authcontext';

export function UncontrolledExample() {
    let {element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}= useContext(Authcontext)
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={element.ImageUrl1}
          alt="First slide"
        />
      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={element.ImageUrl2}
          alt="Second slide"
        />

       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={element.ImageUrl3}
          alt="Third slide"
        />
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src={element.ImageUrl4}
          alt="Third slide"
        />
        </Carousel.Item>
        
    </Carousel>
  );
}

