import React from 'react'
import '../css/Style.css'
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Authcontext } from '../../../Context/Authcontext';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
  Data: {
    id:number;
    company: string;
    ImageUrl1: string;
    ImageUrl2:string;
    ImageUrl3:string;
    ImageUrl4:string;
    Year: string;
    Location: string;
    km: number;
    price: number;
    fuel: string;
};
}
export const List:React.FC<IProps> = ({Data}) => {
 let navigate = useNavigate();
 let {element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}= useContext(Authcontext)
 
 function clicker(){
  
  window.localStorage.removeItem('DATA');
   setelement(element=Data); 
   localStorage.setItem("DATA",JSON.stringify(element))
   navigate('/preview/' + element.id );
 }
 const {pid} = useParams();
 return (
    <div className='list_main'  >
      <div onClick={clicker}  className='image_div' >
      
      {/* <IoMdHeartEmpty className='heart' />
      <BsShare className='share' /> */}
       <img src={Data.ImageUrl1}  alt='image load'/>

       <span>1 Year Warranty | 3 Free Service</span>
       </div>
      <div className='details_div'>
      <div>  <span className='vehicleName' >{Data.company}</span> </div>
      <div> <span>{Data.Location}</span></div>
     <div> <span>{Data.Year}   |   {Data.fuel}   |   {Data.km} Km</span></div>
     <div> <input type='checkbox' ></input>Add to Compare </div>
     <div className='calculateEmi'><span>₹ {Data.price} </span>
     <button  onClick={()=>{alert("All buttons are displayed only for demo ")}} >Calculate EMI</button></div>
     <div className='button_div' >
       <button  onClick={()=>{alert("All buttons are displayed only for demo ")}}>Contact Dealer</button>
       <button  onClick={()=>{alert("All buttons are displayed only for demo ")}}>Book A Test Drive</button>
     </div>
     </div>
    </div>

  )
}
