export const Data = [
    {
        id: 1,
        company: "Vitara Brezza VXI",
        ImageUrl1: "https://i.ibb.co/KL9nW6n/ey-Jid-WNr-ZXQi-Oi-Jtd-HZk-ZWFs-ZXJhc-HB1cy-Is-Imtle-SI6-Ij-Yz-Nz-Qz-MC82-Mz-A2-MTE0-NTdj-Mj-Bj-Mm-V.jpg",
        ImageUrl2:"https://i.ibb.co/sskQhw6/ey-Jid-WNr-ZXQi-Oi-Jtd-HZk-ZWFs-ZXJhc-HB1cy-Is-Imtle-SI6-Ij-Yz-Nz-Qz-MC82-Mz-A2-MTE0-NTdj-Mj-Bj-Mm-V.jpg",
        ImageUrl3:"https://i.ibb.co/DRTL5PD/ey-Jid-WNr-ZXQi-Oi-Jtd-HZk-ZWFs-ZXJhc-HB1cy-Is-Imtle-SI6-Ij-Yz-Nz-Qz-MC82-Mz-A2-MTE0-NTdj-Mj-Bj-Mm-V.jpg",
        ImageUrl4:"https://i.ibb.co/bRFYYSc/ey-Jid-WNr-ZXQi-Oi-Jtd-HZk-ZWFs-ZXJhc-HB1cy-Is-Imtle-SI6-Ij-Yz-Nz-Qz-MC82-Mz-A2-MTE0-NTdj-Mj-Bj-Mm-V.jpg",
        Year: "2022",
        Location: "Vellore",
        km: 5605,
        price: 1030000,
        fuel: 'Petrol',
    },
    {
        id: 2,
        company: "Alto K10",
        ImageUrl1:"https://i.ibb.co/PZdMN9M/image1.jpg",
        ImageUrl2:"https://i.ibb.co/wpQ1W16/image2.jpg",
        ImageUrl3:"https://i.ibb.co/JR2QyGh/image4.jpg",
        ImageUrl4:"https://i.ibb.co/t2DH3Jd/image5.jpg",
        Year: "2020",
        Location: "Banglore",
        km: 25696,
        price: 490000,
        fuel: 'Petrol',
    },
    {
        id: 3,
        company: "Ciaz ALPHA 1.4 MT",
        ImageUrl1:"https://s3.amazonaws.com/mtvdealerappus/612977/6274fea160fc1ae113cb5251-2022-07-19-12-25-41/image1.jpg",
        ImageUrl2:"https://s3.amazonaws.com/mtvdealerappus/612977/6274fea160fc1ae113cb5251-2022-07-19-12-25-41/image2.jpg",
        ImageUrl3:"https://s3.amazonaws.com/mtvdealerappus/612977/6274fea160fc1ae113cb5251-2022-07-19-12-25-41/image4.jpg",
        ImageUrl4:"https://s3.amazonaws.com/mtvdealerappus/612977/6274fea160fc1ae113cb5251-2022-07-19-12-25-41/image5.jpg",
        Year: "2017",
        Location: "banglore",
        km: 23411,
        price: 675000,
        fuel: 'Petrol',

    },
    {
        id: 4,
        company: "Alto 800 LXI (O)",
        ImageUrl1: "https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjYzNzQyNS82MzJlOTJjYmRkMTAwNDYxNTM5ZDExODEtMjAyMi0xMC0xNy0xMS0zOS0xMS9pbWFnZTEuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo2NjksImhlaWdodCI6NDU5LCJmaXQiOiJmaWxsIn19fQ==",
        ImageUrl2:"https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjYzNzQyNS82MzJlOTJjYmRkMTAwNDYxNTM5ZDExODEtMjAyMi0xMC0xNy0xMS0zOS0xMS9pbWFnZTIuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo2NjksImhlaWdodCI6NDU5LCJmaXQiOiJmaWxsIn19fQ==",
        ImageUrl3:"https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjYzNzQyNS82MzJlOTJjYmRkMTAwNDYxNTM5ZDExODEtMjAyMi0xMC0xNy0xMS0zOS0xMS9pbWFnZTQuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo2NjksImhlaWdodCI6NDU5LCJmaXQiOiJmaWxsIn19fQ==",
        ImageUrl4:"https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjYzNzQyNS82MzJlOTJjYmRkMTAwNDYxNTM5ZDExODEtMjAyMi0xMC0xNy0xMS0zOS0xMS9pbWFnZTUuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo2NjksImhlaWdodCI6NDU5LCJmaXQiOiJmaWxsIn19fQ==",
        Year: "2022",
        Location: "Jalpaiguri",
        km: 7540,
        price: 400000,
        fuel: 'Petrol',
    },
    {
        id: 5,
        company: "Celerio",
        ImageUrl1: "https://s3.amazonaws.com/mtvdealerappus/612864/63186ae809dfd56d695a4386-2022-09-20-16-48-55/image1.jpg",
        ImageUrl2:"https://s3.amazonaws.com/mtvdealerappus/612864/63186ae809dfd56d695a4386-2022-09-20-16-48-55/image2.jpg",
        ImageUrl3:"https://s3.amazonaws.com/mtvdealerappus/612864/63186ae809dfd56d695a4386-2022-09-20-16-48-55/image4.jpg",
        ImageUrl4:"https://s3.amazonaws.com/mtvdealerappus/612864/63186ae809dfd56d695a4386-2022-09-20-16-48-55/image5.jpg",
        Year: "2022",
        Location: "Gurugram",
        km: 16700,
        price: 500000,
        fuel: 'Petrol',
    },
   
    {
        id: 7,
        company: "Wagon R",
        ImageUrl1: "https://s3.amazonaws.com/mtvdealerappus/612864/632710619ae0717147fb791e-2022-10-30-14-51-26/image1.jpg",
        ImageUrl2:"https://s3.amazonaws.com/mtvdealerappus/612864/632710619ae0717147fb791e-2022-10-30-14-51-26/image2.jpg",
        ImageUrl3:"https://s3.amazonaws.com/mtvdealerappus/612864/632710619ae0717147fb791e-2022-10-30-14-51-26/image4.jpg",
        ImageUrl4:"https://s3.amazonaws.com/mtvdealerappus/612864/632710619ae0717147fb791e-2022-10-30-14-51-26/image5.jpg",
        Year: "2022",
        Location: "Kollam",
        km: 9090,
        price: 890000,
        fuel: 'Petrol',
    },
    {
        id: 8,
        company: "Eeco STANDARD ",
        ImageUrl1: "https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjEwMjg0NzEvNjMzMjk1NjljYWEwMDYxYTRjN2FjZGU2LTIwMjItMTEtMDItMTctMTYtMjYvaW1hZ2UxLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NjY5LCJoZWlnaHQiOjQ1OSwiZml0IjoiZmlsbCJ9fX0=",
        ImageUrl2:"https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjEwMjg0NzEvNjMzMjk1NjljYWEwMDYxYTRjN2FjZGU2LTIwMjItMTEtMDItMTctMTYtMjYvaW1hZ2UyLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MTYwLCJoZWlnaHQiOjEwOCwiZml0IjoiZmlsbCJ9fX0=",
        ImageUrl3:"https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjEwMjg0NzEvNjMzMjk1NjljYWEwMDYxYTRjN2FjZGU2LTIwMjItMTEtMDItMTctMTYtMjYvaW1hZ2UzLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NjY5LCJoZWlnaHQiOjQ1OSwiZml0IjoiZmlsbCJ9fX0=",
        ImageUrl4:"https://dt5rjsxbvck7d.cloudfront.net/eyJidWNrZXQiOiJtdHZkZWFsZXJhcHB1cyIsImtleSI6IjEwMjg0NzEvNjMzMjk1NjljYWEwMDYxYTRjN2FjZGU2LTIwMjItMTEtMDItMTctMTYtMjYvaW1hZ2U1LmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NjY5LCJoZWlnaHQiOjQ1OSwiZml0IjoiZmlsbCJ9fX0=",
        Year: "2022",
        Location: "Banglore",
        km: 15126,
        price: 675000,
        fuel: 'petrol',
    },
];