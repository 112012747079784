import React, { useContext, useEffect } from 'react'
import { Filters } from './Filters/Filters'
import { List } from './List/List'
import '../Body/css/Style.css'
import { Data } from '../Data/Data'
import { DataHelloar } from '../Data/DataHelloar'
import { BiFilterAlt,BiSortAlt2 } from "react-icons/bi";
import { Header } from '../Header/Header'
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { Authcontext } from '../../Context/Authcontext'
import { useNavigate } from "react-router-dom";

export const Body = () => {
let {element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}= useContext(Authcontext)

const navigate=useNavigate()
localStorage.setItem("DATA_Helloar",JSON.stringify(DataHelloar))
localStorage.setItem("DATA_Truevalue",JSON.stringify(Data))
function imagclick(){
  sethelloar(!helloar)
}

 
  return (
    <div className='body_main' >
       
      <div >
      <button  className = {helloar ? 'version_button_helloar' : 'version_button_truevalue'}  onClick={()=>sethelloar(!helloar)} >
       {helloar ? 'Show True Value Version' : ' Show HelloAR Version'}
      </button>
      </div>
      <Header/>
      <div className='home_bar' >
        <HiOutlineArrowLongLeft onClick={()=>navigate(-1)} className='left_arrow' />
        <span  style={{'fontWeight':'900'}} > 9087 Used Cars for Sale</span> 
      </div>
      <div  className='filterbutton_main'  >
        <button className='filter_button' onClick={()=>{alert("All buttons are displayed only for demo ")}} > <BiFilterAlt style={{'marginBottom':'4px'}} /> Filter</button>
        <button className='sortby_button'  onClick={()=>{alert("All buttons are displayed only for demo ")}} > <BiSortAlt2 style={{'marginRight':'10px'}} />  Sort By</button>
      </div>
    
         
      {helloar ? 
      DataHelloar.map((e)=>{
        
        setelement(element=e)
        return (<List Data={e} />)
      }) 
      : 
      Data.map((e)=>{
        setelement(element=e)
        return (<List Data={e} />)
      }) }

       {filter?<Filters /> : '' }
      
    </div>
  )
}
