import { createContext } from "react";

export interface props  {
        filter:boolean;
        helloar: boolean;
        element:{id:number;
            company: string;
            ImageUrl1: string;
            ImageUrl2: string;
            ImageUrl3: string;
            ImageUrl4: string;
            Year: string;
            Location: string;
            km: number;
            price: number;
            fuel: string;};
        setelement:Function;
        data:[];
        setdata:Function;
        sethelloar: Function;
        setFilter: Function;
 }

export const Authcontext = createContext<props>({} as props)