import React, { useState,useEffect } from 'react'
import { Authcontext } from './Authcontext'
import { Data } from '../Components/Data/Data'


export const Authprovider = ({children}:any) => {
   
    let [data,setdata]= React.useState<any>([])
    const [helloar,sethelloar]=React.useState<boolean>(false)
    const [filter,setFilter]=React.useState<boolean>(false)
    const [element,setelement]=React.useState<any>(false)
   
   useEffect(() => {
   setdata(Data) 
   })
    
  return (
    <Authcontext.Provider value={{element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}} >
        {children}
    </Authcontext.Provider>
  )
}
