import React from 'react'
import App from '../App'
import { Preview } from '../Components/Preview/Preview'
import {Route,Routes} from 'react-router-dom'
import { Body } from '../Components/Body/Body'
import { Iframe } from '../Components/Preview/Iframe'

export const AllRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<Body/>} />
        <Route path="/Preview/:pid" element={<Preview />} />
        <Route path="/Iframe" element={<Iframe />} />
    </Routes>
    
  )
}
