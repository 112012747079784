
  import React, { useEffect } from 'react'
  import { useContext } from 'react'
  import { Authcontext } from '../../Context/Authcontext'
  import { Header } from '../Header/Header'
  import { HiOutlineArrowLongLeft } from "react-icons/hi2";
  import { useNavigate, useParams } from 'react-router-dom';
  import { UncontrolledExample } from '../../Context/Corousal';
  import { useState } from 'react';




  export const Preview= () => {
    
    let {element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}= useContext(Authcontext)
    const [DATA, setDATA] = useState<any>(
      JSON.parse(localStorage.getItem("DATA") || "") || {}
    );
  const navigate=useNavigate(); 
  function  helloarshow(){
    navigate('/iframe') 
  }

  useEffect(()=>{
    setDATA( JSON.parse(localStorage.getItem("DATA") || "") || {})
  setelement(JSON.parse(localStorage.getItem("DATA") || "")  || {} );
   
 

  let elem = helloar ? JSON.parse(localStorage.getItem("DATA_Helloar") || "")  || {} :
                   JSON.parse(localStorage.getItem("DATA_Truevalue") || "")  || {}
  window.localStorage.removeItem('DATA');
  for(let i = 0; i <elem.length;i++){
  
    if(elem[i].id==pid){
     localStorage.setItem("DATA",JSON.stringify(elem[i]))
     setelement(elem[i])
    }
  }

 },[])
const {pid} = useParams();
console.log(pid,'pid');

 function change(){
   sethelloar(!helloar);
   let elem = !helloar ? JSON.parse(localStorage.getItem("DATA_Helloar") || "")  || {} :
                    JSON.parse(localStorage.getItem("DATA_Truevalue") || "")  || {}
   window.localStorage.removeItem('DATA');
   for(let i = 0; i <elem.length;i++){
   
     if(elem[i].id==pid){
      localStorage.setItem("DATA",JSON.stringify(elem[i]))
      setelement(elem[i])
     }
   }
 }

    return (
      <div>
     
      <div >
      <button  className = {helloar ? 'version_button_helloar' : 'version_button_truevalue'}  onClick={change} >
       {helloar ? 'Show True Value Version' : ' Show HelloAR Version'}
      </button>
      </div>
        <div className='preview_main' >
        <Header/>
        <div className='home_bar'>
          <HiOutlineArrowLongLeft onClick={()=>{navigate("/");  window.localStorage.removeItem('DATA') }} className='left_arrow' />
          <span>Home</span>
          <span>|</span>
          <span>Buy Car</span>
          <span>|</span>
          <span>{element.company}</span>
        </div>
  
        {element.ImageUrl3==="" ? <div className='image_div' >
        {/* <IoMdHeartEmpty className='heart' />
        <BsShare className='share' /> */}
        <div  className='preview_image_div' > 
        
        <img onClick={helloarshow} src={element.ImageUrl1} ></img>
          <div onClick={helloarshow}  className='preview_innerdiv' >
          <button onClick={helloarshow} >Tap To view 360 </button>
          </div>
        
        </div>
        
        
        </div> :
        <div className='image_div' >
        {/* <IoMdHeartEmpty className='heart' />
        <BsShare className='share' /> */}
        {/* <img src={element.ImageUrl}  alt='image load'/> 
        < */}
        <UncontrolledExample/>
  
      </div> 
        }
      
      <div className='preview1_price' >
        <span>{element.company}</span>
        <span>{element.price}</span>
      </div>
      
      <div className='preview_details1' >
        
        <div>
          <div> <span>{element.Year}</span>
          <span>|</span>
          <span>{element.km}km</span>
          <span>|</span>
          <span>{element.fuel}</span>
          <span>|</span>
          <span>1 st Owner</span>
          </div>
          <button  onClick={()=>alert("All Buttons are used for demo only")}>Calculate EMI</button>
        </div>
        
          
          <div > <input  type='checkbox'   ></input>
          <span>Add to Compare</span> </div>
        </div>
  
      <div className='preview_buttons' >
        <button  onClick={()=>alert("All Buttons are used for demo only")}>Book a Test Drive</button>
        <button  onClick={()=>alert("All Buttons are used for demo only")}>Contact Dealer</button>
      </div>
  
      </div>
      
        
        
          

        
      </div>
    )
  }
