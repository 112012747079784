import React, { useContext } from 'react'
import '../css/Style.css'
import { AiOutlineClose } from "react-icons/ai";
import { Authcontext } from '../../../Context/Authcontext';


export const Filters = () => {
  let data = useContext(Authcontext)
 
 
  return (
    <div className='filter_main' >

      <div > <AiOutlineClose className='close_button' onClick={()=>data.setFilter(!data.filter)} /> </div>
      <div className='reset_div'>
        <div>Refine Your Results</div>
        <div>RESET</div>
      </div>
     
       
       <div className='range_select' >
       <div>RANGE</div>
        <div>
          <button>50KM</button>
          <button>100KM</button>
          <button>200KM</button>
          <button>500KM</button>
        </div>
       </div>
     
        <div className='truevalue_certified' >
          <div>
          <input type='checkbox' ></input>
          <span>True Value Certified</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Remove Booked Cars</span>
          </div>
        </div>

       <div className='select_budget' >
       <div>Select Budget</div>
        <div>
          <button>Less Than 2L</button>
          <button>2L-4L</button>
          <button>4L-6L</button>
          <button>More than 6L</button>
        </div>
       </div>

       <div className='body_type' >
       <div>Body Type</div>
        <div>
          <button>HatchBack(83)</button>
          <button>Sedan(18)</button>
          <button>Van(12)</button>
          <button>SUV(5)</button>
          <button>MUV(6)</button>
        </div>
       </div>
      
       <div className='filterbybrand' >
           <span >BRAND</span>
       </div>
       
       <div className='filterby_models' >
         
          <div>
          <input type='checkbox' ></input>
          <span>Wagon R</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Alto 800</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Swift</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Alto</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Dzire</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Alto K10</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Eeco</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Celerio</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Baleno</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Omni</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Vitara Brezza</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Ritz</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Ciaz</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Zen Estilo</span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>S-presso </span>
          </div>
          <div>
          <input type='checkbox' ></input>
          <span>Ertiga</span>
          </div>
        </div>

        <div className='age_select' >
       <div>CAR AGE</div>
        <div>
          <button>0 to 1 years</button>
          <button>1 to 3 years</button>
          <button>3 to 5 years</button>
          <button>5 to 7 years</button>
          <button>more than 7 years</button>
        </div>
       </div>

       <div  className='owner'  >
         <div >
           <span>OWNER</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>First owner</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>Second Owner</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>Third Owner</span>
         </div>
        </div>

        <div className='age_select' >
        <div>KILOMETERS DRIVE</div>
        <div>
          <button>10k km</button>
          <button>25k km</button>
          <button>50k km</button>
          <button>75k km</button>
          <button>75k+ km</button>
        </div>
        </div>

        <div className='fueltype' >
         <div>
           <span>FUEL TYPE</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>Petrol</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>Diesel</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>CNG</span>
         </div>
        </div>

        

        <div className='transmission' >
         <div>
           <span>Transmission</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>Manual</span>
         </div>
         <div>
           <input type='checkbox' ></input>
           <span>Automatic</span>
         </div>
        </div>

      </div>

   
  )
}
