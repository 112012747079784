export const DataHelloar = [
{
    id: 1,
    company: "Vitara Brezza VXI",
    ImageUrl1: "https://content.helloviewer.io/uploads/636f86b1d61dc9253926ccff/f87250d2-c5ad-4531-bf7d-a532de6017a9/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=637397cd41448a91d2d44b58&application=TRV%20-%20Virtual%20BG",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2022",
    Location: "Vellore",
    km: 5605,
    price: 1030000,
    fuel: 'Petrol',
},
{
   
    id: 2,
    company: "Alto K10",
    ImageUrl1: "https://content.helloviewer.io/uploads/636f8a5b5a885ab517c23aa3/c9f49832-8f03-45d4-8880-3a021adebdc5/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=6373a03bf054d416cd313127&application=TRV%20-%20Shoot",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2020",
    Location: "Banglore",
    km: 25696,
    price: 490000,
    fuel: 'Petrol',
},
{
    id: 3,
    company: "Ciaz ALPHA 1.4 MT",
    ImageUrl1: "https://content.helloviewer.io/uploads/63712e67a193e4010a9d89ca/758c86dc-4244-429a-911c-ef10acc1c773/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=63739cea86d713a22500f755&application=TRV%20-%20Shoot",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2017",
    Location: "banglore",
    km: 23411,
    price: 675000,
    fuel: 'Petrol',

},
{
    id: 4,
    company: "Alto 800 LXI (O)",
    ImageUrl1: "https://content.helloviewer.io/uploads/636f8a5b5a885ab517c23aa3/c9f49832-8f03-45d4-8880-3a021adebdc5/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=637398a42ba02744ed11f011&application=True%20Value%20Test",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2022",
    Location: "Jalpaiguri",
    km: 7540,
    price: 400000,
    fuel: 'Petrol',
},
{
    id: 5,
    company: "Celerio",
    ImageUrl1:"https://content.helloviewer.io/uploads/63712ea7a193e4010a9d89d0/da377147-d648-4044-a271-62a98e10a26d/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=63739f6686d713a22500f852&application=TRV%20-%20Shoot",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2022",
    Location: "Gurugram",
    km: 16700,
    price: 500000,
    fuel: 'Petrol',
},
{
    id: 6,
    company: "Dzire VXI",
    ImageUrl1: "https://content.helloviewer.io/uploads/63712e1ca193e4010a9d89c2/6c33e213-9ebd-4908-ae2b-996248a7cef4/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=637399805e646307586a6b28&application=True%20Value%20Test",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2015",
    Location: "Banglore",
    km: 46796,
    price: 580000,
    fuel: 'Petrol',
},
{
    id: 7,
    company: "Wagon R",
    ImageUrl1: "https://content.helloviewer.io/uploads/63712e7ba193e4010a9d89cc/b876dee1-114c-4c32-a2f5-f9da71a5671e/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=6374841ad7f41a7142462d41&application=TRV%20-%20Shoot",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2022",
    Location: "Kollam",
    km: 9090,
    price: 890000,
    fuel: 'Petrol',
},
{
    id: 8,
    company: "EECO STANDARD ",
    ImageUrl1: "https://content.helloviewer.io/uploads/63712ca930dd4d9d1ab41ba8/ee62bc02-ca98-48b0-b26d-85b521e451d2/elevated_2_4_3/1.jpg",
    ImageUrl2:"https://car.viewer.helloar.io/?id=637396e45e646307586a6a4f&application=True%20Value%20Test",
    ImageUrl3:"",
    ImageUrl4:"",
    Year: "2022",
    Location: "Banglore",
    km: 15126,
    price: 675000,
    fuel: 'petrol',
},
];