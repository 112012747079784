import React from 'react'
import { AllRoutes } from '../Routes/AllRoutes';
import './Body/css/Style.css'
import { useContext } from 'react';
import { Authcontext } from '../Context/Authcontext';




export const Versions = () => {
  let {element,setelement,filter,helloar,data,setdata,sethelloar,setFilter}= useContext(Authcontext)
  return (
    
    <div className='versions_main' > 
    <div className='version_div' >
    {/* <button  className = {helloar ? 'version_button_helloar' : 'version_button_truevalue'}  onClick={()=>sethelloar(!helloar)} >
       {helloar ? 'Show True Value Version' : ' Show HelloAR Version'}
      </button> */}
    </div>
    <AllRoutes/>     
    </div>
  )
}
